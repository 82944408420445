"use client";
import { useSearchParams } from "next/navigation";
import React from "react";

export type UTMParams = {
	source: string;
	medium: string;
	campaign: string;
	term: string;
	content: string;
};

export const UTMParamsContext = React.createContext<{
	utmParams: UTMParams | null;
	setUTMParams: (utm: UTMParams | null) => void;
}>({
	utmParams: null,
	setUTMParams: (utm: UTMParams | null) => {},
});

const UTM_PARAMS_STORAGE_KEY = "utm_params";

const parseUTMParamsFromSessionStorage = (): UTMParams | null => {
	try {
		const utm_params = window.sessionStorage.getItem(UTM_PARAMS_STORAGE_KEY);
		if (!utm_params) return null;
		return JSON.parse(utm_params);
	} catch (err) {
		console.error(err);
	}
	return null;
};

export const UTMParamsContextProvider: React.FC = ({ children }) => {
	const [utmParams, setUTMParams] = React.useState<UTMParams | null>(null);

	const searchParams = useSearchParams();

	React.useEffect(() => {
		if (typeof window === "undefined") return;
		try {
			let _utmParams = parseUTMParamsFromSessionStorage();

			if (_utmParams) {
				setUTMParams(_utmParams);
			} else {
				const source = searchParams.get("utm_source") || "";
				const medium = searchParams.get("utm_medium") || "";
				const campaign = searchParams.get("utm_campaign") || "";
				const term = searchParams.get("utm_term") || "";
				const content = searchParams.get("utm_content") || "";
				if ([source, medium, campaign, term, content].some(param => !!param)) {
					_utmParams = {
						source: searchParams.get("utm_source") || "",
						medium: searchParams.get("utm_medium") || "",
						campaign: searchParams.get("utm_campaign") || "",
						term: searchParams.get("utm_term") || "",
						content: searchParams.get("utm_content") || "",
					};
					window.sessionStorage.setItem(
						UTM_PARAMS_STORAGE_KEY,
						JSON.stringify(_utmParams)
					);
					setUTMParams(_utmParams);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}, [searchParams]);

	return (
		<UTMParamsContext.Provider value={{ utmParams, setUTMParams }}>
			{children}
		</UTMParamsContext.Provider>
	);
};

export const useUTMParams = () => React.useContext(UTMParamsContext);
